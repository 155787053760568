<template>
  <div class="req-temp-container mt-6">
    <vs-row class="mt-5">
      <vs-card class="fix-pads">
        <div slot="header">
          <div class="flex justify-between items-center">
            <h3 class="font-normal lg:text-2xl md:text-xl text-lg">Plaform profile</h3>
          </div>
        </div>
        <div>
          <vs-row>
            <div>
              <vs-row>
                <vs-col vs-w="12" vs-lg="12" vs-sm="12">
                  <h4 class="font-normal mb-2 lg:text-xl text-lg">Platform details</h4>
                  <hr class="line-hr mt-1"/>
                </vs-col>
                <vs-row class="my-4 md:my-8 grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-x-0 gap-y-4 md:gap-y-0 md:gap-x-16 lg:gap-x-24">
                <vs-col class="col-span-1">
                  <div class="w-full input-grp mb-6">
                    <div>
                      <label class="label w-full text-base block mb-1">Platform name <span class="mid-blue">*</span></label>
                      <vs-input class="w-full" v-validate="'required'" size="large" v-model="platformName" name="platformName" id="platformName"/>
                      <span class="text-danger text-sm" v-show="errors.has('platformName')" >{{ errors.first("platformName") }}</span>
                    </div>
                  </div>
                </vs-col>
                <vs-col class="col-span-1">
                  <div class="w-full input-grp mb-6">
                    <div>
                      <label class="label w-3/4 block mb-1">Website URL <span class="mid-blue">*</span></label>
                      <vs-input class="w-full" v-validate="'required|urlFormat'" size="large" v-model="website" name="website" id="website"/>
                      <span class="text-danger text-sm" v-show="errors.has('website')">{{ errors.first("website") }}</span>
                    </div>
                  </div>
                </vs-col>
                </vs-row>
                <vs-row class="mb-4 md:mb-8 grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 gap-x-0 gap-y-4 md:gap-y-0 md:gap-x-16 lg:gap-x-24">
                <vs-col class="col-span-1">
                  <div class="w-full input-grp mb-6 mr-0">
                    <div>
                      <label class="label w-full text-base block mb-1">Platform description<span class="mid-blue">*</span></label>
                      <vs-textarea class="custom-textarea mb-0 h-full" v-validate="'required'" v-model="platformDescription" name="platformDescription" height="105px !important"/>
                      <span class="text-danger text-sm" v-show="errors.has('platformDescription')" >{{ errors.first("platformDescription") }}</span>
                    </div>
                  </div>
                </vs-col>
                </vs-row>
              </vs-row>
            </div>
          </vs-row>
          <vs-row class="mt-0">
            <vs-col vs-w="12" vs-lg="12" vs-sm="12">
              <h4 class="font-normal mb-2 lg:text-xl text-lg">Logo</h4>
              <hr class="line-hr mb-6 md:mb-8 mt-1"/>
            </vs-col>
            <vs-row>
              <div class="w-full">
                <vs-row>
                  <vs-row>
                    <div class="w-full">
                      <div class="vx-col w-full vs-con-loading__container">
                        <div class="flex upload-img mt-6 md:mt-8 mb-8 md:mb-10 lg:mb-14" v-if="image">
                          <div class="logo-gallery-wrap">
                            <div class="logo-gallery-img p-5">
                              <S3ImageGenerator :document="image" :is-base64="isBase64(image)" :key="image"/>
                              <span class="XCircleIcon">
                                <feather-icon icon="XCircleIcon" class="ml-1" @click="clearImage"/>
                              </span>
                            </div>
                          </div>
                        </div>                 
                        <div class="flex upload-img mt-6 md:mt-8 mb-8 md:mb-10 lg:mb-14 items-center" v-if="!image" style="margin-bottom:54px;">
                          <div class="force-btn-width">
                            <vs-button v-round class="only-border-btn ml-6" @click="openLibrary" size="medium">
                              <folder-icon size="1.5x"class="custom-class"></folder-icon> Choose from library
                            </vs-button>
                          </div>
                        </div>
                        <div class="flex justify-end w-full pt-4 pb-4 md:pb-6" >
                          <div class="actions text-right">
                            <vs-button size="large" v-round :disabled="errors.items.length > 0" @click="updateProfile">Save</vs-button>
                            <a color="" @click="onCancel" class="ml-8 text-button text-sm text-button">Cancel</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </vs-row>
                </vs-row>
              </div>
            </vs-row>
          </vs-row>
        </div>
      </vs-card>
    </vs-row>

    <vs-popup class="vs-popup" size="medium"  title="Logo library" :active.sync="showLibrary">
      <div>
        <div class="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-4 md:gap-6">
          <div v-for="(logo, index) in logoList" :key="index" class="logo-gallery-wrap">
            <div @click="selectLogo(logo.logoImage)" class="logo-gallery-img cursor-pointer">              
              <S3ImageGenerator :document="logo.logoImage" :key="logo.logoImage"/>
            </div>
            <div class="company-logo-caption py-2 md:py-4">
              <p class="text-sm">{{logo.logoId}}</p>
            </div>
          </div>
        </div>  
      </div>
    </vs-popup>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>

    <pop-up :errorsLists="errors" form-name="PlatformProfileForm"></pop-up>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import { mapActions } from "vuex";
import LeavePopup from "@/views/components/LeavePopup";
import PopUp from "@/views/components/ValidationPopup.vue";
import { FolderIcon, UploadIcon } from "vue-feather-icons";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";

const dictionary = {
  en: {
    attributes: {
      platformName: "platform name",
      platformDescription: "platform description",
    },
  },
};
Validator.localize(dictionary);
export default {
  components: {
    PopUp,
    FolderIcon,
    UploadIcon,
    LeavePopup,
    S3ImageGenerator
  },
  data() {
    return {
      logo: "",
      image: "",
      website: "",
      isSaved: false,
      platformName: "",
      platformDescription: "",
      popupActive: false,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
      showLibrary: false,
      logoList: [],
      selectedLogo: "",
    };
  },
  methods: {
    ...mapActions("user", ["updatePlaformProfile", "fetchPlaformProfile"]),
    ...mapActions("logo", ["fetchLogo"]),
    ...mapActions("admin", ["checkLogin"]),
    clearImage() {
      this.image = null;
      if (this.errors.has(`image`)) {
        this.errors.remove(`image`);
      }
    },

    updateProfile() {
      this.$validator.validateAll().then(async (valid) => {
        if (valid) {
          const formData = {};
          formData.platformName = this.platformName;
          formData.platformDescription = this.platformDescription;
          if (this.selectedLogo) {
            formData.logo = this.image;
          }
          formData.website = this.website;

          let obj = {
            id: this.introducerId,
            data: formData,
          };
          this.updatePlaformProfile(obj).then((result) => {
            this.showMessage(result.data.title, result.data.message, "success", "icon-check-circle");
            this.isSaved = true;
          }).catch((err) => {
            this.showMessage("Error" ,err.response.data.message, "danger", "icon-times");
          });
        }
      });
    },
    async getPlaformProfile() {
      await this.fetchPlaformProfile(this.introducerId)
        .then((response) => {
          if (response.data.data.platformName)
            this.platformName = response.data.data.platformName;
          if (response.data.data.platformDescription)
            this.platformDescription = response.data.data.platformDescription;
          if (response.data.data.website)
            this.website = response.data.data.website;
          if (response.data.data.logo)
            this.image = response.data.data.logo;
        })
        .catch((err) => cosole.log(err));
    },
    onCancel() {
      this.isSaved = true;
      this.$router.push({ name: "dashboard" });
    },
    openLibrary() {
      this.showLibrary = true;
    },
    getLogo() {
      this.$vs.loading();
      this.fetchLogo(this.introducerId).then((response) => {
        this.logoList = response.data.data;
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: ex.data.title,
          text: ex.data.message,
          color: "danger",
          position: "top-right",
        });
      });
    },
    selectLogo(logo) {
      this.selectedLogo = logo;
      this.image = this.selectedLogo;
      this.showLibrary = false;
    },
    showMessage(
      title = "Successful",
      message = "Task completed",
      color = "success",
      icon = "icon-check-circle"
    ) {
      this.$vs.notify({
        title: title,
        text: message,
        color: color,
        iconPack: "feather",
        position: "top-right",
        icon: icon,
      });
    },

    popActions(event) {
      switch (event) {
        case "leave":
          this.nextObj();
          break;
        case "save":
          this.popupActive = false;
          this.updateProfile();
          // do save the changes
          break;
        case "cancel":
          this.popupActive = false;
          break;
        default:
          this.popupActive = false;
      }
    },
    isBase64(key) {
      if (typeof key !== 'string' ) {
        return false;
      } 
      const validation = new RegExp(/data:image/);
      return validation.test(key)
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
  },
  mounted() {
    this.checkAdminLogin();
    this.getPlaformProfile();
    this.getLogo();
  },
  beforeRouteLeave(to, from, next) {
    if (this.isFormDirty && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
    } else {
      next();
    }
  },
  created() {
    this.$validator.extend("urlFormat", {
      getMessage: () => "The website field is not a valid url.",
      validate: (value) =>
        value.match(
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
        ) !== null,
    });
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    introducerId() {
      return this.user.userRole === "superAdmin" ? this.user._id : this.user.introducer;
    },
  },
};
</script>
